import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import requestInstance from '../api';
import { getLanguageContent } from '../language';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const entries = [
  {
    id: 1,
    name: "ミラノ風ドリア",
    category: "ドリア",
    price: "200",
    taxInPrice: "220",
    cal: "400",    
    allergies: [
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
      {
        name: "小麦",
        isExist: false
      },
    ]
  }
]

type Props = {
  langCode: string;
}

const DataTable: React.FC<Props> = ({langCode}) => {
  const theme = useTheme();
  const tableScroll = React.useRef<number>(0);
  const [isProductSearch, setIsProductSearch] = React.useState<boolean>(true);
  const [categories, setCategories] = React.useState<string[]>([]);
  const [category, setCategory] = React.useState<string>();
  const [allergyChecks, setAllergyChecks] = React.useState<boolean[]>([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<any>([]);
  const [product, setProduct] = React.useState<any>(entries[0]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [lastModified, setLastModified] = React.useState<string | null>(null);

  React.useLayoutEffect(() => {
    setIsLoading(true);
    window.addEventListener("scroll", toggleVisibility);
    setCategories(getLanguageContent(langCode)!.content.categories)
    setCategory(getLanguageContent(langCode)!.content.categories[0])
    requestInstance.get(`/view?lg=${langCode}`).then((response) => {
      setIsLoading(false);
      setLastModified(response.data.lastModified);
      setProducts(response.data.body);
    }).catch(err => {
      console.error('err:', err);
    });
  }, []);

  React.useEffect(() => {
    const headerElement = document.getElementById('header');
    const tableElement = document.getElementById('table');
    if(headerElement && tableElement) {
      headerElement.scrollLeft = tableScroll.current;
      tableElement.scrollLeft = tableScroll.current;
    }
  })

  const toggleVisibility = () => {
    if (window.scrollY > document.getElementById('table-container')!.getBoundingClientRect().top + window.pageYOffset){ 
      setIsVisible(true);
      return;
    }
    setIsVisible(false);
    return;
  }

  const handleXScroll = () => {
    const headerElement = document.getElementById('header');
    const tableElement = document.getElementById('table');
    if(headerElement && tableElement) {
      headerElement.scrollLeft = tableElement.scrollLeft;
      tableScroll.current = tableElement.scrollLeft;
    }
  }

  const handleTabChange = () => {
    if(!isProductSearch) setAllergyChecks([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    setIsProductSearch(!isProductSearch);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleAllergyChecksChange = (isChecked: boolean, index: number) => {
    let entry = [...allergyChecks];
    entry[index] = isChecked;
    setAllergyChecks(entry);
  };

  const handleOpenModal = (id: string) => {
    const product = products.find((value: any) => value.id === id)
    if(product === null) return;
    console.log(product)
    setProduct(product);
    setIsOpenModal(true);
  }

  const handleCloseModal = (event: object, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setIsOpenModal(false);
  }

  const MainContainer = styled('div')(({theme}) => ({
    width: '100%',
    maxWidth: 1024,
    margin: 'auto',
  }));

  const TabItem = styled('div')(({theme}) => ({
    cursor: "pointer",
    marginRight: 20,
  }));

  const TabBorder = styled('div')(({theme}) => ({
    height: 4,
    width: "100%",
    backgroundColor: "#2E691C",
    borderRadius: 2,
  }));

  const StickyHeader = styled('div')(({theme}) => ({
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    width: "100%",
    boxSizing: "border-box",
    zIndex: 200,
    "@media screen and (max-width: 1024px)": {
      padding: !isProductSearch ? `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}` : `0 ${theme.spacing(3)}`,
    },
  }))

  const TableWrapper = styled('div')(({theme}) => ({
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    "::-webkit-scrollbar": {
      display: 'none'
    }
  }))

  const TdModalNutrition = styled('td')(({theme}) => ({
    minWidth: "50px",
    align: "right",
    borderColor: "#DBDBDB",
    borderWidth: 0,
    borderStyle: "solid"
  }))

  const ModalTableWrap = styled('div')(({theme}) => ({
    "@media screen and (max-width: 700px)": {
      display: "none"
    },
  }))

  const MobileModalTableWrap = styled('div')(({theme}) => ({
    "@media screen and (min-width: 700px)": {
      display: "none"
    },
  }))

  const NotifyWrap = styled('div')(({theme}) => ({
    "@media screen and (min-width: 700px)": {
      textAlign: "right"
    },
    marginBottom: 10
  }))

  const TableTh = styled('th')(({theme}) => ({
    "@media screen and (min-width: 700px)": {
      height: 180,
    },
    "@media screen and (max-width: 700px)": {
      height: 120,
    }
  }))

  const TableLeftTh = styled('th')(({theme}) => ({
    "@media screen and (min-width: 700px)": {
      width: 170,
    },
    "@media screen and (max-width: 700px)": {
      width: 130,
    }
  }))

  const TableLeftTd = styled('td')(({theme}) => ({
    "@media screen and (min-width: 700px)": {
      width: 170,
    },
    "@media screen and (max-width: 700px)": {
      width: 130,
    }
  }))

  const modalAllergiesTable = (startIndex: number, endIndex: number) => {
    const items = [];
    let firstRow = [];
    let secondRow = [];
    for (let i = startIndex; i < endIndex; i++) {
      const allergy = product.allergies.find((allergy: any) => allergy.name === getLanguageContent(langCode)?.content.headers[i]);
      firstRow.push(
        <td
          style={{
            padding: 8,
            textAlign: "center",
            width: 80,
            backgroundColor: "#EDFFD1",
            borderColor: "#DBDBDB",
            borderTopWidth: i - startIndex < 9 ? 1 : 0,
            borderRightWidth: i === endIndex-1 || firstRow.length === 8 ? 1 : 0,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderStyle: "solid"
          }}
        >
          {getLanguageContent(langCode)?.content.headers[i]}
        </td>
      )
      secondRow.push(
        <td
          style={{
            padding: 8,
            textAlign: "center",
            width: 80,
            backgroundColor: "#FFFFFF",
            borderColor: "#DBDBDB",
            borderTopWidth: 0,
            borderRightWidth: i === endIndex-1 || firstRow.length === 9 ? 1 : 0,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderStyle: "solid"
          }}
        >
          {allergy ? allergy.isExist ? allergy.isUsedOriginal ? "▲" : "●" : "×" : ""}
        </td>
      )
      if(firstRow.length > 8){
        items.push(<tr>{firstRow}</tr>)
        items.push(<tr>{secondRow}</tr>)
        firstRow = [];
        secondRow = [];
      }
    }
    items.push(<tr>{firstRow}</tr>)
    items.push(<tr>{secondRow}</tr>)
    return (
      <table style={{
        borderSpacing: "0",
      }}>
        <tbody>
          { items }
        </tbody>
      </table>
    );
  };

  const mobileModalAllergiesTable = (startIndex: number, endIndex: number) => {
    const items = [];
    for (let i = startIndex; i < endIndex; i++) {
      const allergy = product.allergies.find((allergy: any) => allergy.name === getLanguageContent(langCode)?.content.headers[i]);
      items.push(
        <tr>
          <td
            style={{
              padding: 8,
              width: "50%",
              textAlign: "center",
              backgroundColor: "#EDFFD1",
              borderColor: "#DBDBDB",
              borderTopWidth: i === startIndex ? 1 : 0,
              borderRightWidth: 0,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderStyle: "solid"
            }}
          >
            {getLanguageContent(langCode)?.content.headers[i]}
          </td>
          <td
            style={{
              padding: 8,
              width: "50%",
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              borderColor: "#DBDBDB",
              borderTopWidth: i === startIndex ? 1 : 0,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderStyle: "solid"
            }}
          >
            {allergy ? allergy.isExist ? allergy.isUsedOriginal ? "▲" : "●" : "×" : ""}
          </td>
        </tr>
      )
    }
    return (
      <table style={{
        borderSpacing: "0",
        width: "100%"
      }}>
        <tbody>
          { items }
        </tbody>
      </table>
    );
  };

  return (
    <>
    <MainContainer
      sx={{
        marginBottom: theme.spacing(5) 
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13
      }}>
        <Link to="/" style={{textDecoration: 'none', color: 'black'}}>{`◀︎ ${getLanguageContent(langCode)?.content.backHome}`}</Link>
        {lastModified && `${lastModified} 更新`}
      </div>
      <div style={{
        color: '#2E691C',
        marginBottom: theme.spacing(5),
      }}>
        <h1>{getLanguageContent(langCode)?.content.title}</h1>
      </div>
      <div
        style={{
          marginBottom: theme.spacing(3),
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
        {getLanguageContent(langCode)?.content.firstMemo}
      </div>
      <div
        style={{
          marginBottom: theme.spacing(3),
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
        {getLanguageContent(langCode)?.content.secondMemo}
      </div>
      <div
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
        {getLanguageContent(langCode)?.content.thirdMemo}
      </div>
      <div
        style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        }}
      >
         {getLanguageContent(langCode)?.content.fourthMemo}
      </div>
    </MainContainer>
    <MainContainer
      sx={{
        display: 'flex',
      }}
    >
      <TabItem
        sx={{
          color: isProductSearch ? '#2E691C' : null,
        }}
        onClick={() => {
          handleTabChange();
        }}
      >
        <h3
          style={{
            padding: "0 10px",
            marginBottom: 10
          }}
        >
          {getLanguageContent(langCode)?.content.firstTabTitle}
        </h3>
        {isProductSearch && <TabBorder/>}
      </TabItem>
      <TabItem
        sx={{
          color: !isProductSearch ? '#2E691C' : null,
        }}
        onClick={() => {
          handleTabChange();
        }}
      >
        <h3
          style={{
            padding: "0 10px",
            marginBottom: 10
          }}
        >
          {getLanguageContent(langCode)?.content.secondTaTitle}
        </h3>
        {!isProductSearch && <TabBorder/>}
      </TabItem>
    </MainContainer>
    <div style={{
      borderBottom: "2px solid #F7F7F7",
      marginBottom: theme.spacing(5),
    }}/>
    <MainContainer
      sx={{
        marginBottom: theme.spacing(5) 
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={category}
          onChange={handleCategoryChange}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MainContainer>
    <MainContainer id="table-container">
      <TableWrapper
        id="table"
        onScroll={handleXScroll}
      >
        <table
          style={{
            width: 1024,
            tableLayout: "fixed",
            borderSpacing: 0
          }}
        >
          <thead>
            {!isProductSearch &&
              <tr>
                <TableLeftTh
                  style={{
                    padding: "0 8px 20px 8px",
                    textAlign: "left",
                    fontWeight: "normal",
                    verticalAlign: "middle",
                    zIndex: 10,
                    backgroundColor: "#FFFFFF",
                    position: "sticky",
                    top: 0,
                    left: 0,
                  }}
                >
                  {getLanguageContent(langCode)?.content.fourthTableTitle}
                </TableLeftTh>
                {getLanguageContent(langCode)?.content.headers.map((header, index) => (
                  <th
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Checkbox
                      checked={allergyChecks[index]}
                      onChange={(event) => {
                        handleAllergyChecksChange(event.target.checked, index)
                      }}
                      color="success"
                      style={{
                        verticalAlign: "top",
                        padding: 0,
                      }}
                    />
                  </th>
                ))}
              </tr>
            }
            <tr>
              <TableLeftTh
                rowSpan={2}
                style={{
                  padding: 8,
                  textAlign: "left",
                  verticalAlign: "middle",
                  backgroundColor: "#F7F7F7",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  borderWidth: "0 2px 0 0",
                  borderStyle: "solid",
                  borderColor: "#fff",
                }}
              >
                {getLanguageContent(langCode)?.content.firstTableTitle}
              </TableLeftTh>
              <th
                colSpan={8}
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#F7F7F7",
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}
              >
                {getLanguageContent(langCode)?.content.secondTableTitle}
              </th>
              <th
                colSpan={21}
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#F7F7F7"
                }}
              >
                {getLanguageContent(langCode)?.content.thirdTableTitle}
              </th>
            </tr>
            <tr>
              {getLanguageContent(langCode)?.content.headers.map((header, index) => (
                <TableTh
                  style={{
                    verticalAlign: "top",
                    textAlign: "center",
                    width: 29,
                    padding: "8px 0",
                    backgroundColor: index < 8 ? "#98D47F" : "#EDFFD1",
                    borderWidth: getLanguageContent(langCode)?.content.headers.length!-1 === index ? "0 0 0 0" : "0 2px 0 0",
                    borderStyle: "solid",
                    borderColor: "#fff",
                  }}
                >
                  <span style={{writingMode: "vertical-lr", WebkitWritingMode: "vertical-lr"}}>{header}</span>
                </TableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {products.filter((product: any) => { 
              if(category === getLanguageContent(langCode)!.content.categories[0]) {
                const checks = allergyChecks.filter((value) => value);
                if(checks.length){
                  let isAllergy = false;
                  for(let i = 0; i < getLanguageContent(langCode)!.content.headers.length; i++){
                    const allergy = product.allergies.find((allergy: any) => allergy.name === getLanguageContent(langCode)!.content.headers[i]);
                    if(allergyChecks[i] && allergy) {
                      if(allergy.isExist) isAllergy = true;
                    }
                  }
                  return isAllergy;
                }
                return true;
              }
              if(category === product.category && !isProductSearch){
                const checks = allergyChecks.filter((value) => value);
                if(checks.length){
                  let isAllergy = false;
                  for(let i = 0; i < product.allergies.length; i++){
                    const headerIndex = getLanguageContent(langCode)!.content.headers.findIndex((header: any) => product.allergies[i].name === header);
                    if(allergyChecks[headerIndex]) {
                      if(product.allergies[i].isExist) isAllergy = true;
                    }
                  }
                  return isAllergy;
                }
                return category === product.category;
              }
              return category === product.category;
            }).map((product: any, i: any) => (
              <tr style={{margin: -2}}>
                <TableLeftTd
                  style={{
                    padding: 8,
                    textAlign: "left",
                    backgroundColor: i % 2 ? "#EDFFD1" : "#FFFFFF",
                    position: "sticky",
                    left: 0,
                    borderWidth: "0 2px 0 0",
                    borderStyle: "solid",
                    borderColor: "#fff",
                  }}
                >
                  <Link to="" onClick={() => {handleOpenModal(product.id)}} preventScrollReset={true} style={{color: "black"}}>{product.name}</Link>
                </TableLeftTd>
                {getLanguageContent(langCode)?.content.headers.map((header, index) => {
                  const allergy = product.allergies.find((allergy: any) => allergy.name === header);
                  return(
                    <td
                      style={{
                        textAlign: "center",
                        backgroundColor: i % 2 ? index < 8 ? "#98D47F" : "#EDFFD1" : index < 8 ? "#EDFFD1" : "#FFFFFF",
                        borderWidth: getLanguageContent(langCode)?.content.headers.length!-1 === index ? "0 0 0 0" : "0 2px 0 0",
                        borderStyle: "solid",
                        borderColor: "#fff",
                      }}
                    >
                      {allergy ? allergy.isExist ? allergy.isUsedOriginal ? "▲" : "●" : "" : NaN}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading &&
          <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 25}}>
            <CircularProgress color="success"/>
          </div>
        }
      </TableWrapper>
    </MainContainer>
    <StickyHeader sx={{display: isVisible ? "block" : "none"}}>
      <div id="header" style={{overflowX: 'hidden', position: "relative"}}>
        <table
          style={{
            width: 1024,
            tableLayout: "fixed",
            borderSpacing: 0,
            margin: "0 auto",
          }}
        >
          <thead>
            {!isProductSearch &&
              <tr>
                <TableLeftTh
                  style={{
                    padding: "10px 8px 20px 8px",
                    textAlign: "left",
                    fontWeight: "normal",
                    verticalAlign: "middle",
                    zIndex: 10,
                    backgroundColor: "#FFFFFF",
                    position: "sticky",
                    top: 0,
                    left: 0,
                  }}
                >
                  {getLanguageContent(langCode)?.content.fourthTableTitle}
                </TableLeftTh>
                {getLanguageContent(langCode)?.content.headers.map((header, index) => (
                  <th
                    style={{
                      paddingTop: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <Checkbox
                      checked={allergyChecks[index]}
                      onChange={(event) => {
                        handleAllergyChecksChange(event.target.checked, index)
                      }}
                      color="success"
                      style={{
                        verticalAlign: "top",
                        padding: 0,
                      }}
                    />
                  </th>
                ))}
              </tr>
            }
            <tr>
              <TableLeftTh
                rowSpan={2}
                style={{
                  padding: 8,
                  textAlign: "left",
                  verticalAlign: "middle",
                  backgroundColor: "#F7F7F7",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  borderWidth: "0 2px 0 0",
                  borderStyle: "solid",
                  borderColor: "#fff",
                }}
              >
                {getLanguageContent(langCode)?.content.firstTableTitle}
              </TableLeftTh>
              <th
                colSpan={8}
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#F7F7F7",
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}
              >
                {getLanguageContent(langCode)?.content.secondTableTitle}
              </th>
              <th
                colSpan={21}
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#F7F7F7"
                }}
              >
                {getLanguageContent(langCode)?.content.thirdTableTitle}
              </th>
            </tr>
            <tr>
              {getLanguageContent(langCode)?.content.headers.map((header, index) => (
                <TableTh
                  style={{
                    verticalAlign: "top",
                    textAlign: "center",
                    width: 29,
                    padding: "8px 0",
                    backgroundColor: index < 8 ? "#98D47F" : "#EDFFD1",
                    borderWidth: getLanguageContent(langCode)?.content.headers.length!-1 === index ? "0 0 0 0" : "0 2px 0 0",
                    borderStyle: "solid",
                    borderColor: "#fff",
                  }}
                >
                  <span style={{writingMode: "vertical-lr", WebkitWritingMode: "vertical-lr"}}>{header}</span>
                </TableTh>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    </StickyHeader>
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      disableRestoreFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: 'scroll'
      }}
    >
      <Box sx={{
        "@media screen and (min-width: 1024px)": {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "100%",
          maxWidth: 900,
          maxHeight: "85%",
          overflowY: "auto",
        },
        bgcolor: 'background.paper',
        border: '1px solid #F7F7F7',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
      }}>
        <div style={{
          width: "100%",
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginBottom: theme.spacing(3),
        }}>
          <Typography id="product-name" variant="h5" component="h2" sx={{fontWeight: "bold"}}>
            {product.name}
          </Typography>
          <ModalTableWrap>
            <div style={{textAlign: "right"}}>
              <Typography id="product-name" variant="h6" component="h2" sx={{fontWeight: "bold"}}>
                {product.includigTaxPrice ? `${product.includigTaxPrice} ${getLanguageContent(langCode)?.content.modal.price}` : "不明"}
              </Typography>
              <table align="right" style={{
                borderSpacing: 0,
                marginTop: theme.spacing(1)
              }}>
                <tbody>
                  <tr>
                    <TdModalNutrition>
                      {getLanguageContent(langCode)?.content.modal.cal}
                    </TdModalNutrition>
                    <TdModalNutrition>
                      {product.calorie ? `${product.calorie} kcal` : "不明"}
                    </TdModalNutrition>
                  </tr>
                  <tr>
                    <TdModalNutrition>
                      {getLanguageContent(langCode)?.content.modal.salt}
                    </TdModalNutrition>
                    <TdModalNutrition>
                      {product.salt ? `${product.salt} g` : "不明"}
                    </TdModalNutrition>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalTableWrap>
        </div>
        <MobileModalTableWrap>
          <Typography id="product-name" variant="h6" component="h2" sx={{fontWeight: "bold", marginBottom: theme.spacing(5)}}>
            {product.includigTaxPrice ? `${product.includigTaxPrice} ${getLanguageContent(langCode)?.content.modal.price}` : "不明"}
          </Typography>
          <Typography id="allergy-title" variant="h6" component="h2" sx={{marginBottom: theme.spacing(2)}}>
            {getLanguageContent(langCode)?.content.modal.nutrition}
          </Typography>
          <div style={{margin: `${theme.spacing(2)} 0 ${theme.spacing(5)}`}}>
            <table style={{
              borderSpacing: 0,
            }}>
              <tbody>
                <tr>
                  <TdModalNutrition>
                    {getLanguageContent(langCode)?.content.modal.cal}
                  </TdModalNutrition>
                  <TdModalNutrition>
                    {product.calorie ? `${product.calorie} kcal` : "不明"}
                  </TdModalNutrition>
                </tr>
                <tr>
                  <TdModalNutrition>
                    {getLanguageContent(langCode)?.content.modal.salt}
                  </TdModalNutrition>
                  <TdModalNutrition>
                    {product.salt ? `${product.salt} g` : "不明"}
                  </TdModalNutrition>
                </tr>
              </tbody>
            </table>
          </div>
        </MobileModalTableWrap>
        <NotifyWrap>
          {getLanguageContent(langCode)?.content.notify}
        </NotifyWrap>
        <Typography id="allergy-title" variant="h6" component="h2" sx={{marginBottom: theme.spacing(2)}}>
          {getLanguageContent(langCode)?.content.modal.allergen}
        </Typography>
        <Typography id="allergy-sub-title" variant="subtitle1" component="h2">
          {getLanguageContent(langCode)?.content.secondTableTitle}
        </Typography>
        <div style={{margin: `${theme.spacing(2)} 0`}}>
          <ModalTableWrap>
            {modalAllergiesTable(0, 8)}
          </ModalTableWrap>
          <MobileModalTableWrap>
            {mobileModalAllergiesTable(0, 8)}
          </MobileModalTableWrap>
        </div>
        <Typography id="allergy-sub-title" variant="subtitle1" component="h2">
          {getLanguageContent(langCode)?.content.thirdTableTitle}
        </Typography>
        <div style={{margin: `${theme.spacing(2)} 0`, overflowX: "auto"}}>
          <ModalTableWrap>
            {modalAllergiesTable(8, 29)}
          </ModalTableWrap>
          <MobileModalTableWrap>
            {mobileModalAllergiesTable(8, 29)}
          </MobileModalTableWrap>
        </div>
        <div style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: theme.spacing(3)
        }}>
          <Button variant="outlined" color="success" onClick={() => {setIsOpenModal(false)}}>
            {getLanguageContent(langCode)?.content.modal.close}
          </Button>
        </div>
      </Box>
    </Modal>
    </>
  )
}

export default DataTable;