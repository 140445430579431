import React from 'react';
import { getLanguageContent } from '../language';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate }  from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';



import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

type Props = {
  langCode: string;
}

const Home: React.FC<Props> = ({langCode}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const MainContainer = styled('div')(({theme}) => ({
    width: '100%',
    maxWidth: 1024,
    margin: 'auto',
  }));

  const TitleWrapper = styled('div')(({theme}) => ({
    width: '100%',
    margin: `${theme.spacing(1)} 0`,
    color: '#5C4B37'
  }));

  const TitleBottomLine = styled('div')(({theme}) => ({
    width: '100%',
    height: 2,
    backgroundColor: "#EEE"
  }));

  const TextWrapper = styled('div')(({theme}) => ({
    width: '100%',
    margin: `${theme.spacing(3)} 0`,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  }));

  const BoldTextWrapper = styled('div')(({theme}) => ({
    width: '100%',
    margin: `${theme.spacing(3)} 0`,
    fontWeight: 'bold',
    color: '#5C4B37',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  }));

  return (
    <>
    <MainContainer>
      <TitleWrapper>
        <h3 style={{margin: 0}}>{getLanguageContent(langCode)?.content.top.firstTitle}</h3>
      </TitleWrapper>
      <TitleBottomLine/>
      <TextWrapper>{getLanguageContent(langCode)?.content.top.firstBody}</TextWrapper>
      <TitleWrapper>
        <h3 style={{margin: 0}}>{getLanguageContent(langCode)?.content.top.secondTitle}</h3>
      </TitleWrapper>
      <TitleBottomLine/>
      <BoldTextWrapper sx={{marginBottom: theme.spacing(1)}}>{getLanguageContent(langCode)?.content.top.thirdTitle}</BoldTextWrapper>
      <TextWrapper sx={{marginTop: theme.spacing(1)}}>{getLanguageContent(langCode)?.content.top.secondBody}</TextWrapper>
      <BoldTextWrapper sx={{marginBottom: theme.spacing(1)}}>{getLanguageContent(langCode)?.content.top.fourthTitle}</BoldTextWrapper>
      <TextWrapper sx={{marginTop: theme.spacing(1)}}>{getLanguageContent(langCode)?.content.top.thirdBody}</TextWrapper>
      <div
        style={{
          color: "black",
          backgroundColor: "#FFFFFF",
          padding: 10,
          textAlign: "center",
          fontSize: "1.17em",
          fontWeight: "bold",
          border: "1px solid black",
          margin: `${theme.spacing(5)} auto`
        }}
      >
        <span>{getLanguageContent(langCode)?.content.top.alert}</span>
      </div>
      <BoldTextWrapper>{getLanguageContent(langCode)?.content.top.fourthBody}</BoldTextWrapper>
      <TextWrapper>{getLanguageContent(langCode)?.content.top.fifthBody}</TextWrapper>
      <BoldTextWrapper sx={{fontSize: "1.17em"}}>{getLanguageContent(langCode)?.content.top.sixthBody}</BoldTextWrapper>
      <TextWrapper sx={{fontSize: "0.8em"}}>{getLanguageContent(langCode)?.content.top.seventhBody}</TextWrapper>
      <TextWrapper sx={{marginBottom: "60px"}}>{getLanguageContent(langCode)?.content.top.eighthBody}</TextWrapper>
      <div
        style={{
          maxWidth: "600px",
          color: "white",
          backgroundColor: "#2E691C",
          padding: 15,
          textAlign: "center",
          fontSize: "1.17em",
          fontWeight: "bold",
          borderRadius: 10,
          margin: `0 auto`,
          cursor: "pointer"
        }}
        onClick={() => navigate('/allergy')}
      >
        {getLanguageContent(langCode)?.content.top.btn}
      </div>
    </MainContainer>
    </>
  )
}

export default Home;