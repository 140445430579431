import React from 'react';
import { BrowserRouter, Routes, Route, useSearchParams } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import { getLanguageContent } from './language';

import Home from './views/Home';
import DataTable from './views/DataTable';
import ScrollTop from './components/ScrollTop';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import LanguageIcon from '@mui/icons-material/Language';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import logo from "./saizeriya-logo.jpeg";

import './App.css';

type langCodes = "ja" | "en" | "zh";

export default function App() {
  //const [searchParams] = useSearchParams();
  const [languageCode, setLanguageCode] = React.useState<langCodes>("ja");

  React.useLayoutEffect(() => {
    //const langCode = searchParams.get("lang");
    //if(langCode === "ja" || langCode === "en" || langCode === "zh"){
    //  setLanguageCode(langCode)
    //}
  }, [])

  const Toolbar = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1024px",
    minHeight: 64,
    margin: "auto",
    boxSizing: "border-box",
    "@media screen and (max-width: 1024px)": {
      padding: `0 ${theme.spacing(3)}`,
    },
  }));

  const MainContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    padding: `${theme.spacing(3)} 0`,
    "@media screen and (max-width: 1024px)": {
      padding: `${theme.spacing(3)}`,
    },
  }));

  const HeaderBar = () => {
    return (
      <AppBar
        position="relative"
        color="inherit"
        elevation={3}
      >
          <Toolbar>
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <img src={logo} alt="picture" style={{height: 44, marginRight: 10}}/>
              <Typography
                noWrap 
                variant="h6"
                sx={{
                  "@media screen and (max-width: 700px)": {
                    display: "none"
                  },
                }}
              >
                {getLanguageContent(languageCode)?.content.title}
              </Typography>
            </div>
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <Button
                sx={{
                  marginRight: 2,
                  color: '#5C4B37',
                  "@media screen and (max-width: 700px)": {
                    display: "none"
                  },
                }}
                onClick={() => {window.location.href = "https://www.saizeriya.co.jp/"}}
              >
                {getLanguageContent(languageCode)?.content.toTop}
              </Button>
              <IconButton
                size="large"
                sx={{
                  marginTop: "4px",
                  marginRight: 2,
                  display: "none",
                  "@media screen and (max-width: 700px)": {
                    display: "block"
                  },
                }}
                onClick={() => {window.location.href = "https://www.saizeriya.co.jp/"}}
              >
                <HomeOutlinedIcon/>
              </IconButton>
              <FormControl>
                <Select
                  value={languageCode}
                  onChange={(event) => {
                    if(
                      event.target.value === 'ja' || 
                      event.target.value === 'en' || 
                      event.target.value === 'zh'
                    ) setLanguageCode(event.target.value);
                  }}
                  input={
                    <Input
                      id="input-with-icon-adornment"
                      sx={{width: 150}}
                      startAdornment={
                        <InputAdornment position="start">
                          <LanguageIcon/>
                        </InputAdornment>
                      }
                    />
                  }
                >
                  <MenuItem value={"ja"}>日本語</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                  <MenuItem value={"zh"}>中文</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Toolbar>
      </AppBar>
    )
  }

  return (
    <>
      {HeaderBar()}
      <MainContainer>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path={`/`} element={<Home langCode={languageCode}/>} />
            <Route path={`/allergy`} element={<DataTable langCode={languageCode}/>} />
          </Routes>
        </BrowserRouter>
      </MainContainer>
    </>
  )
}
