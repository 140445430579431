import axios from "axios"

const requestInstance = axios.create({
  baseURL: 'https://allergy.saizeriya.co.jp',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
})

export default requestInstance;
