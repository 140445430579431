const contents = [
  {
    langCode: "ja",
    content: {
      headers: [`小麦`,	`卵`,	`乳`,	`えび`,	`かに`,	`そば`,	`落花生`,	`くるみ`,	`大豆`,	`豚肉`,	`牛肉`,	`鶏肉`,	`あわび`,	`いか`,	`いくら`,	`オレンジ`,	`キウイフルーツ`,	`さけ`,	`さば`,	`バナナ`,	`マカダミアナッツ`,	`もも`,	`やまいも`,	`りんご`,	`ゼラチン`,	`魚介類`, `ごま`,	`カシューナッツ`,	`アーモンド`],
      categories: [`全ての商品`, `グランド`, `キッズ`, `季節`, `ランチ`, `テイクアウト`, `モーニング`],
      title: `アレルゲン一覧`,
      firstMemo: `≪食品衛生法に基づき、下記について表示しております≫`,
      secondMemo: `法令で規定する特定原材料8品目：卵・乳・小麦・蕎麦・落花生・えび・かに・くるみ\n表示を推奨する特定原材料に準ずるもの20品目：大豆・牛肉・鶏肉・豚肉・アワビ・イカ・いくら・さけ・さば・オレンジ・キウイ・もも・りんご・マカダミアナッツ・山芋・ゼラチン・バナナ・ゴマ・カシューナッツ・アーモンド`,
      thirdMemo: `※当店では、食器や調理器具が共用のため、微量混入等がある場合があります。\n※アレルギー物質による感受は個人個人で、あるいは体調で大きな差が出ます。`,
      fourthMemo: `本表は、あくまで参考としてご利用いただき、最終的な判断は専門医にご相談の上、お客様ご自身でご判断いただきますようお願い致します。\n※表中の●印がその原料を使用していることを示します。\n※▲は「モッツァレラチーズの水牛の乳」、「ペコリーノチーズの羊の乳」を示しています。`,
      firstTableTitle: `商品名`,
      secondTableTitle: `法令で規定する\n特定原材料8品目`,
      thirdTableTitle: `表示を推奨する特定原材料に準ずるもの20品目 + 魚介類`,
      fourthTableTitle: `該当`,
      firstTabTitle: `商品から検索`,
      secondTaTitle: `アレルギー物質から検索`,
      backHome: `アレルゲンTopに戻る`,
      notify: `この表示値は、目安です`,
      top: {
        firstTitle: `アレルギーとアレルゲンについて`,
        secondTitle: `サイゼリヤでは、食品衛生法に基づき、以下を表現しております。`,
        thirdTitle: `１）法令で規定する特定現在料 8 品目`,
        fourthTitle: `２）表示を推奨する特定原材料に準ずるもの 20 品目`,
        firstBody: `アレルギーとは、食品に含まれる成分によって起こされる過剰な免疫反応を指します。\nアレルギー反応を引き起こす原因物質をアレルゲンといい、アレルギーを引き起こすことが明らかになった食品のうち、発症数、重篤度から表示の必要性の高い８品目を特定原材料としています。`,
        secondBody: `卵・乳・小麦・蕎麦・落花生・えび・かに・くるみ`,
        thirdBody: `大豆・牛肉・鶏肉・豚肉・アワビ・イカ・いくら・さけ・さば・オレンジ・キウイ・もも・りんご・マカダミアナッツ・山芋・ゼラチン・バナナ・ゴマ・カシューナッツ・アーモンド`,
        fourthBody: `ご注意いただきたい事項`,
        fifthBody: `調理、盛付け過程においては、アレルギー物質の二次的混入を防ぐために細心の注意を払っていますが、以下の点について十分ご理解の上、ご利用ください。`,
        sixthBody: `１. キッチン内の通常調理器具を使用して調理いたします。\n２. 店内で洗浄した食器にて盛付け致します。\n３. コンタミネーションについては十分ご理解の上、ご利用ください。`,
        seventhBody: `※コンタミネーションとは?\n原材料としては使用していないにも関わらず、調理・提供の際にアレルギー物質が微量混入してしまう場合をいいます。\nお客様の症状の程度、またその日の体調により、アレルギー物質の微量な混入でも発症する場合がございます。過敏な方、症状が重篤な方は特にご注意ください。`,
        eighthBody: `店舗にもアレルギー物質一覧表をご用意していますので、係のものにお申し付けください。お客様ご自身でご判断いただきますようお願い致します。`,
        alert: `必ずお読みください。`,
        btn: `アレルゲン・カロリー塩分情報一覧を見る/検索`
      },
      modal: {
        price: `円（税込）`,
        nutrition: `栄養情報`,
        cal: `エネルギー：`,
        salt: `食塩相当量：`,
        allergen: `アレルギー情報`,
        close: `閉じる`,
      },
      toTop: `Topに戻る`,
    }
  },
  {
    langCode: "en",
    content: {
      headers: [`Wheat`, `Eggs`, `Milk`, `Shrimp/Prawn`, `Crab`, `Buckwheat`, `Peanuts`, `Walnuts`, `Soybeans`, `Pork`, `Beef`, `Chicken`, `Abalone`, `Squid`, `Salmon roe`, `Oranges`, `Kiwi fruits`, `Salmon`, `Mackerel`, `Bananas`, `Macadamia nuts`, `Peaches`, `Yams`, `Apples`, `Gelatin`, `Seafood`, `Sesame`, `Cashew nuts`, `Almonds`],
      categories: [`All`, `Grand Menu`, `Kids`, `Seasonal`, `Lunch`, `Take-out`, `Morning`],
      title: `Allergen List`,
      firstMemo: `<<Information on the allergens listed below is provided following the Food Sanitation Act.>>`,
      secondMemo: `Eight specified ingredients requiring mandatory labelling by law: eggs, milk, wheat, buckwheat, peanuts, shrimp/prawn, crab and walnuts\n20Items equivalent to specified ingredients of which labelling is recommended: soybeans, beef, chicken, pork, abalone, squid, salmon roe, salmon, mackerel, oranges, kiwi fruits, peaches, apples, macadamia nuts, yams, gelatin, bananas, sesame, cashew nuts and almonds`,
      thirdMemo: `*As tableware and cooking utensils are shared at Saizeriya restaurants, there is a possibility of minuscule contamination.\n*Sensitivity to allergens varies greatly between individuals. It also significantly changes depending on health conditions.`,
      fourthMemo: `Please use this list solely as a reference. Consult medical specialists and make final decisions at your responsibility.\n*When the symbol "●" is marked for a particular ingredient and dish, it indicates that that dish contains that particular ingredient.\n*The symbol "▲" indicates water buffalo milk in the Buffalo Mozzarella Cheese or sheep milk in the Pecorino Romano.`,
      firstTableTitle: `Dish`,
      secondTableTitle: `Eight specified ingredients requiring mandatory labelling by law`,
      thirdTableTitle: `20items equivalent to specified ingredients of which labelling is recommended + Seafood`,
      fourthTableTitle: `Applicable`,
      firstTabTitle: `Search by dish`,
      secondTaTitle: `Search by allergen`,
      backHome: `Back to allergen top page`,
      notify: `These indicated values are for reference only.`,
      top: {
        firstTitle: `Allergies and Allergens`,
        secondTitle: `Saizeriya expresses the following in accordance with the Food Sanitation Act of Japan.`,
        thirdTitle: `(1) 8 specific ingredients stipulated by the law`,
        fourthTitle: `(2) 20 sub-specific ingredients in which labeling is recommended`,
        firstBody: `An allergy is an excessive immune response that is caused by an ingredient in a food.\nThe substances that cause allergic reactions are called allergens, and among the foods that have been found to cause allergic reactions, eight are designated as specific ingredients that require clear labeling based on the number of cases and severity of allergic reactions.`,
        secondBody: `Egg, milk, wheat, buckwheat, peanut, shrimp, crab, walnut`,
        thirdBody: `Soybean, beef, chicken, pork, abalone, squid, salmon roe, salmon, mackerel, orange, kiwi, peach, apple, macadamia nuts, yam, gelatin, banana, sesame, cashew nut, almond`,
        fourthBody: `Matters of note`,
        fifthBody: `We take great care in our cooking and serving process to prevent secondary contamination of allergens. Please be sure to fully understand the following points before enjoying our food.`,
        sixthBody: `1. We use normal cooking utensils in our kitchen to prepare food.\n2.We serve food on tableware that has been cleaned inside our restaurant.\n3. Please be fully aware of contamination before enjoying our food.`,
        seventhBody: `※What is contamination?\nIt refers to when an allergen is not used as an ingredient for a dish but trace amounts of the allergen is mixed in during the preparation and serving process.\nDepending on the severity of a person's allergic symptoms and physical condition, even a small amount of allergens may cause a reaction. Please take special care if you are hypersensitive or have severe symptoms.`,
        eighthBody: `A list of allergens is also available at our restaurants. Please ask a staff member for the list if needed. Please use your own judgment.`,
        alert: `Please be sure to read the following.`,
        btn: `View or search allergen list`
      },
      modal: {
        price: `Yen（Tax included）`,
        nutrition: `Nutrition Information`,
        cal: `Calories：`,
        salt: `Salt：`,
        allergen: `Allergen Information`,
        close: `Close`,
      },
      toTop: `To top page`,
    }
  },
  {
    langCode: "zh",
    content: {
      headers: [`小麦`,	`蛋`,	`奶`,	`虾`,	`蟹`,	`荞麦`,	`花生`, `核桃`,	`大豆`,	`猪肉`,	`牛肉`,	`鸡肉`,	`鲍鱼`,	`鱿鱼`,	`三文鱼子`,	`橙子`,	`猕猴桃`,	`三文鱼`,	`青花鱼`,	`香蕉`,	`澳洲坚果`,	`桃子`,	`山药`,	`苹果`,	`食用明胶`,	`鱼贝类`, `芝麻`,	`腰果`,	`榛果`],
      categories: [`全部`, `主`, `孩子们`, `季节性的`, `午餐`, `外带`, `早餐`],
      title: `主菜单过敏源一览`,
      firstMemo: `《依据食品卫生法，标注如下内容》`,
      secondMemo: `依据法令规定的8种特定食材: 蛋、奶、小麦、荞麦、花生、虾、蟹、核桃\n依据推荐标注的20种特定食材: 大豆、牛肉、鸡肉、猪肉、鲍鱼、鱿鱼、三文鱼子、三文鱼、青花鱼、橙子、猕猴桃、桃子、苹果、澳洲坚果、山药、食用明胶、香蕉、芝麻、腰果、榛果`,
      thirdMemo: `※本店餐具与烹调器具为共用，因此可能存在微量混入等情况。\n※过敏物质引起的反应因人而异，或因身体状况而存在着很大的差异。`,
      fourthMemo: `本表仅供参考，在专科医生建议的基础上，由顾客根据自身情况作出最终判断。\n※一览表中●表示使用该食材。\n※▲表示“马苏里拉奶酪中的水牛奶” 、“佩科里诺干酪中的羊奶”。`,
      firstTableTitle: `菜品名`,
      secondTableTitle: `依据法令规定的8种特定食材`,
      thirdTableTitle: `依据推荐标注的20种特定食材 + 鱼贝类`,
      fourthTableTitle: `适用`,
      firstTabTitle: `按菜品名搜索`,
      secondTaTitle: `按单过敏源搜索`,
      backHome: `返回过敏原首页`,
      notify: `这些指示值仅供参考。`,
      top: {
        firstTitle: `关于过敏及过敏原`,
        secondTitle: `萨莉亚餐厅中按照食品卫生法展示有如下信息。`,
        thirdTitle: `1）法律法规中规定的8个品类特定现有材料`,
        fourthTitle: `2）建议显示的符合特定原材料的20个品类`,
        firstBody: `过敏是指食品中所含成分引发的过度免疫反应。\n引起过敏反应的物质称为过敏原，在明显会引起过敏的食品中，根据发病次数、严重程度，将极有必要显示的8个品类作为特定原材料。`,
        secondBody: `蛋、奶、小麦、荞麦、花生、虾、蟹、核桃`,
        thirdBody: `大豆、牛肉、鸡肉、猪肉、鲍鱼、乌贼、盐渍鲑鱼子、鲑鱼、青花鱼、橘类、猕猴桃、桃子、苹果、澳洲坚果、山芋、明胶、香蕉、芝麻、腰果、杏仁`,
        fourthBody: `需要注意的事项`,
        fifthBody: `为防止过敏物质二次混入，烹饪、装盘过程中大家都密切注意、格外小心，就餐前请您充分了解如下事项`,
        sixthBody: `１. 使用厨房内的普通烹饪器具进行烹饪。\n２. 使用店内清洗干净的餐具盛放食物。\n３. 就餐前请充分了解污染(contamination)相关信息。`,
        seventhBody: `※何为污染?\n尽管未用作原材料，但于烹饪及供应时还是微量混入了过敏物质。\n根据客人症状的程度、当日身体的状况，即便微量混入了过敏物质也有可能发病。过敏人员、症状严重的人员请特别小心。`,
        eighthBody: `餐厅中还备有过敏物质一览表，请吩咐工作人员出示。请您自行判断。`,
        alert: `请务必阅读。`,
        btn: `查看或搜索过敏原列表`
      },
      modal: {
        price: `日圆（含税）`,
        nutrition: `营养信息`,
        cal: `卡路里：`,
        salt: `盐：`,
        allergen: `过敏原信息`,
        close: `关闭`,
      },
      toTop: `到首页`,
    }
  },
]

export const getLanguageContent  = (langCode: string) => {
  const content = contents.find((element) => element.langCode === langCode);
  if(content) return content;
  return null;
}
